<template>
  <v-card class="mx-auto" :loading="loading" :disabled="loading">
    <v-card-title>
      <v-row dense>
        <v-col cols="12" xs="12" md="8">
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn
                v-on="on"
                small
                plain
                icon
                :to="{
                  name: 'servicios.rs_refounds.show',
                  params: { rs_id: id },
                }"
              >
                <v-icon>mdi-chevron-left</v-icon>
              </v-btn>
            </template>
            <span v-text="'Átras'" />
          </v-tooltip>
          <span
            class="text-h6 ml-1"
            v-text="(data.rs ? data.rs.folio + ' | ' : '') + $route.meta.title"
          />
        </v-col>
        <v-col cols="12" xs="12" md="4" class="text-right">
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn
                v-on="on"
                fab
                x-small
                class="ml-1"
                color="info"
                @click.stop="faqs_dlg = true"
                :loading="faqs_ldg"
                :disabled="faqs.length == 0"
              >
                <v-icon> mdi-help </v-icon>
              </v-btn>
            </template>
            <span v-text="'Ayuda'" />
          </v-tooltip>
        </v-col>
      </v-row>
    </v-card-title>
    <v-card-text v-if="data.rs">
      <v-row>
        <v-col cols="12">
          <v-card tile>
            <v-toolbar elevation="1" dense>
              <v-toolbar-title>
                <span class="text-caption" v-text="'ASEGURADO'" />
              </v-toolbar-title>
              <v-spacer />
              <v-toolbar-items />
            </v-toolbar>
            <v-card-text>
              <v-row dense>
                <v-col cols="12" xs="12" md="3">
                  <ViewData
                    label="ID SM / Póliza"
                    :value="data.rs.enrollment"
                  />
                </v-col>
                <v-col cols="12" xs="12" md="3">
                  <ViewData label="Nombre" :value="data.rs.name" />
                </v-col>
                <v-col cols="12" xs="12" md="3">
                  <ViewData label="A. Paterno" :value="data.rs.first_surname" />
                </v-col>
                <v-col cols="12" xs="12" md="3">
                  <ViewData
                    label="A. Materno"
                    :value="data.rs.second_surname"
                  />
                </v-col>
                <v-col cols="12" xs="12" md="6">
                  <ViewData
                    label="Nombre de oficina"
                    :value="data.rs.office_address"
                  />
                </v-col>
                <v-col cols="12" xs="12" md="6">
                  <ViewData label="Contratante" :value="data.rs.contractor" />
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>

        <v-col cols="12">
          <v-card tile>
            <v-toolbar elevation="1" dense>
              <v-toolbar-title>
                <span class="text-caption" v-text="'SOLICITUD'" />
              </v-toolbar-title>
              <v-spacer />
              <v-toolbar-items />
            </v-toolbar>
            <v-card-text>
              <v-row dense>
                <v-col cols="12" xs="12" md="3">
                  <ViewData label="Tipo" :value="data.rs.rs_type.type" />
                </v-col>
                <v-col cols="12" xs="12" md="3">
                  <ViewData label="C. electrónico" :value="data.rs.email" />
                </v-col>
                <v-col cols="12" xs="12" md="3">
                  <ViewData
                    label="C. electrónico alt."
                    :value="data.rs.email2"
                  />
                </v-col>
                <v-col cols="12" xs="12" md="3">
                  <ViewData label="Teléfono" :value="data.rs.phone" />
                </v-col>
                <v-col cols="12">
                  <ViewData
                    label="Detalle de la solicitud"
                    :value="data.rs.detail"
                  />
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12">
          <v-card tile>
            <v-toolbar elevation="1" dense>
              <v-toolbar-title>
                <span class="text-caption" v-text="'FACTURAS'" />
              </v-toolbar-title>
            </v-toolbar>
            <v-spacer />
            <v-card-text class="px-0 pb-0 pt-1">
              <v-stepper v-model="step" vertical flat>
                <v-stepper-step :complete="step > 1" step="1">
                  Documentación y factura(s)
                </v-stepper-step>
                <v-stepper-content step="1">
                  <v-row>
                    <v-col cols="12">
                      <v-form
                        v-on:submit.prevent
                        ref="data_docs_form"
                        lazy-validation
                      >
                        <v-row dense>
                          <v-col cols="12" class="grey darken-1 white--text">
                            <h4 v-text="'DOCUMENTACIÓN'" class="mt-1 ml-3" />
                          </v-col>
                          <v-col cols="12" class="mt-3">
                            <v-row dense>
                              <v-col
                                cols="12"
                                v-for="(doc, i) in data.documents"
                                :key="i"
                              >
                                <v-row dense>
                                  <v-col cols="12" xs="12" md="6">
                                    <v-file-input
                                      v-if="!doc.id"
                                      :label="
                                        'Archivo digital (' + (i + 1) + ')*'
                                      "
                                      v-model="doc.file"
                                      accept="image/*,.xlsx,.xls,.doc,.docx,.txt,.pdf"
                                      :rules="rules.fileLmtReq"
                                      show-size
                                      dense
                                    />
                                    <div v-else>
                                      <span
                                        class="text-caption font-weight-bold"
                                        v-text="
                                          'Archivo digital (' + (i + 1) + ')'
                                        "
                                      />
                                      <br />
                                      <v-btn
                                        x-small
                                        class="my-2"
                                        :href="
                                          url_documents +
                                          '/rs_documents/' +
                                          doc.url
                                        "
                                        target="_blank"
                                      >
                                        Ver
                                        <v-icon right> mdi-file </v-icon>
                                      </v-btn>
                                    </div>
                                  </v-col>
                                  <v-col cols="12" xs="12" md="5">
                                    <v-text-field
                                      v-if="!doc.id"
                                      label="Descripción"
                                      v-model="doc.description"
                                      maxlength="100"
                                      type="text"
                                      dense
                                    />
                                    <ViewData
                                      v-else
                                      label="Descripción"
                                      :value="doc.description"
                                    />
                                  </v-col>
                                  <v-col
                                    cols="12"
                                    xs="12"
                                    md="1"
                                    class="text-right"
                                  >
                                    <v-tooltip v-if="!doc.id" left>
                                      <template v-slot:activator="{ on }">
                                        <v-btn
                                          v-on="on"
                                          icon
                                          small
                                          color="error"
                                          @click="fileRemove(i)"
                                        >
                                          <v-icon medium>
                                            mdi-file-remove
                                          </v-icon>
                                        </v-btn>
                                      </template>
                                      <span
                                        v-text="`Eliminar documento (${i + 1})`"
                                      />
                                    </v-tooltip>
                                  </v-col>
                                  <v-col cols="12">
                                    <v-divider class="pb-1" />
                                  </v-col>
                                </v-row>
                              </v-col>
                              <v-col cols="12" sm="12" md="6">
                                <v-btn
                                  small
                                  block
                                  color="warning"
                                  @click.prevent="fileAdd"
                                >
                                  Documento
                                  <v-icon right> mdi-plus </v-icon>
                                </v-btn>
                              </v-col>
                            </v-row>
                          </v-col>
                        </v-row>

                        <v-row>
                          <v-col cols="12" />
                          <v-col
                            v-if="
                              login.permissions.rss_rs_refounds_with_bills &&
                              login.permissions.rss_rs_refounds_with_bills
                                .update
                            "
                            cols="12"
                          >
                            <v-checkbox
                              label="Incluir facturas"
                              v-model="data.rs.refound_with_bills"
                              color="success"
                              dense
                              class="mt-1 mr-1"
                            />
                          </v-col>
                        </v-row>

                        <div v-if="data.rs.refound_with_bills">
                          <v-row v-for="(doc, i) in data.docs" :key="i" dense>
                            <v-col cols="12" class="grey darken-3 white--text">
                              <v-row dense>
                                <v-col cols="10">
                                  <h4
                                    v-text="'FACTURA (' + (i + 1) + ')'"
                                    class="mt-1 ml-3"
                                  />
                                </v-col>
                                <v-col cols="2" class="text-right mt-1">
                                  <v-tooltip v-if="i > 0 && !doc.url_pdf" left>
                                    <template v-slot:activator="{ on }">
                                      <v-btn
                                        v-on="on"
                                        text
                                        icon
                                        x-small
                                        color="white"
                                        @click.prevent="docDelete(i)"
                                      >
                                        <v-icon> mdi-close </v-icon>
                                      </v-btn>
                                    </template>
                                    <span v-text="'Eliminar'" />
                                  </v-tooltip>
                                </v-col>
                              </v-row>
                            </v-col>
                            <v-col cols="12" xs="12" md="6">
                              <v-file-input
                                v-if="!doc.url_pdf"
                                v-model="doc.pdf"
                                label="PDF"
                                accept=".pdf"
                                show-size
                                :rules="rules.fileLmtReq"
                              />
                              <v-btn
                                v-else
                                x-small
                                class="my-2"
                                :href="
                                  url_documents +
                                  '/rs_refound_bills/' +
                                  doc.url_pdf
                                "
                                target="_blank"
                              >
                                Ver PDF
                                <v-icon right> mdi-file </v-icon>
                              </v-btn>
                            </v-col>
                            <v-col cols="12" xs="12" md="6">
                              <v-file-input
                                v-if="!doc.url_xml"
                                v-model="doc.xml"
                                label="XML"
                                accept=".xml"
                                show-size
                                :rules="rules.fileLmtReq"
                              />

                              <v-btn
                                v-else
                                x-small
                                class="my-2"
                                color="info"
                                :href="
                                  url_documents +
                                  '/rs_refound_bills/' +
                                  doc.url_xml
                                "
                                target="_blank"
                              >
                                Ver XML
                                <v-icon right> mdi-xml </v-icon>
                              </v-btn>
                            </v-col>
                          </v-row>
                        </div>
                      </v-form>
                    </v-col>
                    <v-col cols="12">
                      <v-row dense>
                        <v-col cols="12" class="mt-2">
                          <v-divider />
                        </v-col>
                        <v-col cols="12" sm="12" md="6">
                          <v-btn
                            v-if="data.rs.refound_with_bills"
                            small
                            block
                            color="warning"
                            @click.prevent="docsAdd"
                          >
                            Factura
                            <v-icon right> mdi-plus </v-icon>
                          </v-btn>
                        </v-col>
                        <v-col cols="12" sm="12" md="6">
                          <v-btn
                            small
                            block
                            color="primary"
                            @click.prevent="docsVerify"
                          >
                            Continuar
                            <v-icon right> mdi-chevron-right </v-icon>
                          </v-btn>
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-row>
                </v-stepper-content>
                <v-stepper-step :complete="step > 2" step="2">
                  Detalle
                </v-stepper-step>
                <v-stepper-content step="2">
                  <div v-if="data.rs.refound_with_bills">
                    <v-row
                      v-for="(rs_refound_bill, i) in data.rs_refound_bills"
                      :key="i"
                      dense
                    >
                      <v-col
                        cols="12"
                        :class="
                          (rs_refound_bill.find_xml_folio ? 'red' : 'grey') +
                          ' darken-3 white--text'
                        "
                      >
                        <h4
                          v-text="
                            'FACTURA (' +
                            (i + 1) +
                            ') | ' +
                            rs_refound_bill.xml_folio +
                            (rs_refound_bill.find_xml_folio
                              ? ' | CARGADADA EN BD'
                              : '')
                          "
                          class="py-1 px-1"
                        />
                      </v-col>
                      <v-col cols="12" xs="12" md="4">
                        <ViewData
                          label="Emisor"
                          :value="
                            rs_refound_bill.emisor_code_tax +
                            ' | ' +
                            rs_refound_bill.emisor_legal_name
                          "
                        />
                      </v-col>
                      <v-col cols="12" xs="12" md="4">
                        <ViewData
                          label="Receptor"
                          :value="
                            rs_refound_bill.receptor_code_tax +
                            ' | ' +
                            rs_refound_bill.receptor_legal_name
                          "
                        />
                      </v-col>
                      <v-col cols="12" xs="12" md="4">
                        <ViewData
                          label="Fecha"
                          :value="rs_refound_bill.xml_date"
                        />
                      </v-col>
                      <v-col cols="12">
                        <v-simple-table dense>
                          <template v-slot:default>
                            <thead>
                              <tr>
                                <th width="40px">#</th>
                                <th>Descripción</th>
                                <th width="180px">Cantidad</th>
                                <th width="180px">Valor unitario</th>
                                <th width="180px">Importe</th>
                                <th width="180px">IVA</th>
                                <th width="180px">Total</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr
                                v-for="(
                                  rs_refound_bill_item, j
                                ) in rs_refound_bill.rs_refound_bill_items"
                                :key="j"
                              >
                                <td v-text="j + 1" />
                                <td v-text="rs_refound_bill_item.item" />
                                <td v-text="rs_refound_bill_item.quantity" />
                                <td
                                  v-text="
                                    numberFormat(rs_refound_bill_item.amount)
                                  "
                                />
                                <td
                                  v-text="
                                    numberFormat(
                                      rs_refound_bill_item.total_amount
                                    )
                                  "
                                />
                                <td
                                  v-text="
                                    rs_refound_bill_item.tax +
                                    '% | ' +
                                    numberFormat(
                                      rs_refound_bill_item.tax_amount
                                    )
                                  "
                                />
                                <td>
                                  <b
                                    v-text="
                                      numberFormat(
                                        rs_refound_bill_item.total_tax_amount
                                      )
                                    "
                                  />
                                </td>
                              </tr>
                            </tbody>
                          </template>
                        </v-simple-table>
                      </v-col>
                    </v-row>
                  </div>
                  <div v-else>
                    <h4>Reembolso sin facturas</h4>
                    <small>
                      Si existen previamente cargadas estas serán eliminadas
                    </small>
                  </div>
                  <v-row dense>
                    <v-col cols="12" class="mt-2">
                      <v-divider />
                    </v-col>
                    <v-col cols="12" sm="12" md="6">
                      <v-btn small block @click.prevent="docsDef">
                        <v-icon left> mdi-chevron-left </v-icon>
                        Atrás
                      </v-btn>
                    </v-col>
                    <v-col cols="12" sm="12" md="6">
                      <v-btn
                        block
                        color="success"
                        @click.prevent="docsSubmit"
                        :disabled="find_xml_folios"
                      >
                        Total {{ numberFormat(data.amount) }} | Guardar
                        <v-icon right> mdi-chevron-right </v-icon>
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-stepper-content>
              </v-stepper>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="12">
          <v-btn x-small color="warning" @click.prevent="rsRefoundRejectedDlg">
            <v-icon left> mdi-file-cancel </v-icon>
            Rechazar o Datos complementarios
          </v-btn>
        </v-col>
      </v-row>
    </v-card-text>
    <v-dialog
      v-model="rs_refound_rejected_dlg"
      scrollable
      persistent
      max-width="600px"
    >
      <v-card
        tile
        :disabled="rs_refound_rejected_dlg_ldg"
        :loading="rs_refound_rejected_dlg_ldg"
      >
        <v-toolbar dark dense>
          <v-toolbar-title> RECHAZAR O DATOS COMPLEMENTARIOS</v-toolbar-title>
          <v-spacer />
          <v-toolbar-items>
            <v-btn icon dark @click="rs_refound_rejected_dlg = false">
              <v-icon> mdi-close </v-icon>
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <v-card-text v-if="rs_refound_rejected">
          <v-form
            v-on:submit.prevent
            ref="rs_refound_rejected_form"
            lazy-validation
          >
            <v-row>
              <v-col cols="12">
                <v-spacer />
              </v-col>
              <v-col cols="12">
                <v-select
                  label="Tipo"
                  v-model="rs_refound_rejected.rs_refound_rejected_type_id"
                  item-value="id"
                  :items="rs_refound_rejected_types"
                  :item-text="(item) => item.rs_refound_rejected_type"
                  :loading="rs_refound_rejected_types_ldg"
                  dense
                  :rules="rules.required"
                />
              </v-col>
              <v-col cols="12">
                <v-textarea
                  rows="3"
                  label="Observación"
                  v-model="rs_refound_rejected.observation"
                  dense
                  :rules="rules.required"
                />
              </v-col>
              <v-col cols="12">
                <v-btn
                  block
                  small
                  color="warning"
                  @click.prevent="rsRefoundRejectedSubmit"
                >
                  Enviar
                  <v-icon right> mdi-send </v-icon>
                </v-btn>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
      </v-card>
    </v-dialog>
    <FaqDlg v-model="faqs_dlg" :faqs="faqs" />
  </v-card>
</template>

<script>
import Axios from "axios";
import {
  URL_API,
  headersToken,
  rules,
  msgAlert,
  msgConfirm,
  toFormData,
  URL_DOCUMENTS,
} from "../../control";
import ViewData from "../../components/ViewData.vue";
import FaqDlg from "../../components/FaqDlg.vue";

export default {
  components: {
    ViewData,
    FaqDlg,
  },
  data() {
    return {
      loading: true,
      id: parseInt(this.$route.params.id),
      login: this.$store.getters.getLogin,
      data: {
        rs: null,
        documents: [],
        docs: [
          {
            id: null,
            url_pdf: null,
            url_xml: null,
            pdf: null,
            xml: null,
          },
        ],
        quantity: 0,
        amount: 0,
        tax_amount: 0,
        rs_refound_bills: [],
      },
      step: 1,
      rules: rules(),
      url_documents: URL_DOCUMENTS,
      find_xml_folios: false,
      rs_refound_rejected_types: [],
      rs_refound_rejected_types_ldg: true,
      rs_refound_rejected: null,
      rs_refound_rejected_dlg: false,
      rs_refound_rejected_dlg_ldg: false,
      faqs: [],
      faqs_dlg: false,
      faqs_ldg: true,
    };
  },
  methods: {
    numberFormat(v) {
      return Number(parseFloat(v).toFixed(2)).toLocaleString("en-US", {
        style: "currency",
        currency: "USD",
      });
    },
    fileAdd() {
      this.data.documents.push({
        id: null,
        active: 1,
        document_id: null,
        description: "",
        file: null,
      });
    },
    fileRemove(i) {
      this.$swal
        .fire(msgConfirm(`¿Confirma eliminar el documento (${i + 1})?`))
        .then((resp) => {
          if (resp.isConfirmed) {
            this.data.documents.splice(i, 1);
          }
        });
    },
    docsAdd() {
      this.data.docs.push({
        id: null,
        url_pdf: null,
        url_xml: null,
        pdf: null,
        xml: null,
      });
    },
    docDelete(i) {
      this.$swal
        .fire(msgConfirm("¿Confirma eliminar la factura (" + (i + 1) + ")?"))
        .then((res) => {
          if (res.isConfirmed) {
            this.data.docs.splice(i, 1);
          }
        });
    },
    docsVerify() {
      if (this.$refs.data_docs_form.validate()) {
        this.loading = true;

        let obj = {};

        for (let i = 0; i < this.data.docs.length; i++) {
          if (this.data.docs[i].url_xml == null) {
            obj["document_bill_xml_" + i] = this.data.docs[i].xml;
          }

          this.data.docs[i].rs_id = this.id;
        }

        obj.docs = this.data.docs;

        Axios.post(
          URL_API + "/rs/refound/bills/analyze/multi",
          toFormData(obj),
          headersToken(this.login.token)
        ).then((res) => {
          if (res.data.success) {
            this.find_xml_folios = res.data.data.find_xml_folios;
            this.data.quantity = res.data.data.quantity;
            this.data.amount = res.data.data.amount;
            this.data.tax_amount = res.data.data.tax_amount;
            this.data.rs_refound_bills = res.data.data.rs_refound_bills;
            this.step = 2;
          } else {
            this.$swal.fire(msgAlert("error", res.data.message));
            console.log(res.data.message);
          }

          this.loading = false;
        });
      } else {
        this.$swal.fire(
          msgAlert(
            "error",
            "Formulario invalido, revisa los detalles señalados"
          )
        );
      }
    },
    docsDef() {
      this.data.quantity = 0;
      this.data.amount = 0;
      this.data.tax_amount = 0;
      this.data.rs_refound_bills = [];
      this.step = 1;
    },
    docsSubmit() {
      this.$swal
        .fire(msgConfirm("Confirma cargar facturas de reembolso"))
        .then((res) => {
          if (res.isConfirmed) {
            this.loading = true;

            let obj = {};
            obj.rs_id = this.data.rs.id;
            obj.refound_with_bills = this.data.rs.refound_with_bills;

            if (obj.refound_with_bills) {
              for (let i = 0; i < this.data.docs.length; i++) {
                if (this.data.docs[i].url_xml == null) {
                  obj["document_rs_refound_bill_pdf_" + i] =
                    this.data.docs[i].pdf;
                  obj["document_rs_refound_bill_xml_" + i] =
                    this.data.docs[i].xml;
                }
              }
            }

            obj.rs_refound_bills = this.data.rs_refound_bills;
            obj.documents = this.data.documents;

            Axios.post(
              URL_API + "/rs/refound/bills/multi",
              toFormData(obj),
              headersToken(this.login.token)
            ).then((res) => {
              this.$swal.fire(
                msgAlert(
                  res.data.success ? "success" : "error",
                  res.data.message
                )
              );

              if (res.data.success) {
                this.$router.push({
                  name: "servicios.rs_refounds.show",
                  params: {
                    rs_id: this.id,
                  },
                });
              } else {
                console.log(res.data.message);
              }

              this.loading = false;
            });
          }
        });
    },
    rsRefoundRejectedDlg() {
      this.rs_refound_rejected = {
        observation: "",
        rs_refound_rejected_type_id: null,
        rs_id: this.id,
      };
      this.rs_refound_rejected_dlg_ldg = false;
      this.rs_refound_rejected_dlg = true;
    },
    rsRefoundRejectedSubmit() {
      if (this.$refs.rs_refound_rejected_form.validate()) {
        this.$swal
          .fire(msgConfirm("¿Confirma enviar observación?"))
          .then((res) => {
            if (res.isConfirmed) {
              this.rs_refound_rejected_dlg_ldg = true;

              Axios.post(
                URL_API + "/rs/refound/rejected",
                this.rs_refound_rejected,
                headersToken(this.login.token)
              ).then((res) => {
                this.$swal.fire(
                  msgAlert(
                    res.data.success ? "success" : "error",
                    res.data.message
                  )
                );

                if (res.data.success) {
                  this.$router.push({
                    name: "servicios.rs_refounds.show",
                    params: {
                      id: this.id,
                    },
                  });
                } else {
                  console.log(res.data.message);
                }

                this.rs_refound_rejected_dlg_ldg = false;
              });
            }
          });
      } else {
        this.$swal.fire(
          msgAlert(
            "error",
            "Formulario invalido, revisa los detalles señalados"
          )
        );
      }
    },
  },
  mounted() {
    Axios.get(
      URL_API + "/faqs/faq_interfaces/" + this.$route.meta.title,
      headersToken(this.login.token)
    ).then((resp) => {
      this.faqs = resp.data.data;
      this.faqs_ldg = false;
    });

    Axios.get(
      `${URL_API}/catalog?name=rs_refound_rejected_types&no_order=false`,
      headersToken(this.login.token)
    ).then((res) => {
      this.rs_refound_rejected_types = res.data.data;
      this.rs_refound_rejected_types_ldg = false;
    });

    Axios.get(
      URL_API + `/rss/${this.id}/3`,
      headersToken(this.login.token)
    ).then((res) => {
      this.data.rs = res.data.data;

      if (this.data.rs.documents.length > 0) {
        this.data.documents = [];

        for (let rs_document of this.data.rs.documents) {
          const document = {
            active: rs_document.active,
            description: rs_document.description,
            id: rs_document.id,
            rs_id: rs_document.rs_id,
            url: rs_document.url,
            document_id: null,
            file: null,
          };

          this.data.documents.push(document);
        }
      }

      if (this.data.rs.rs_refound_bills.length > 0) {
        this.data.docs = [];

        for (let rs_refound_bill of this.data.rs.rs_refound_bills) {
          const doc = {
            id: rs_refound_bill.id,
            url_pdf: rs_refound_bill.url_pdf,
            url_xml: rs_refound_bill.url_xml,
            pdf: null,
            xml: null,
          };

          this.data.docs.push(doc);
        }
      }

      this.loading = false;
    });
  },
};
</script>